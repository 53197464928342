.projectCodeSnippet_modalHeader_10tB0kc {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.projectCodeSnippet_content_3pv1rYc {
  background-color: white !important;
}

.projectCodeSnippet_highLight_3ttWL-4 {
  background-color: rgba(204, 0, 0, 0.05);
  color: #cc0000;
  padding: 2px 5px;
  border-radius: 3px;
}

.projectCodeSnippet_snippetsWrapper_1Wt7mYM {
  position: relative
}

.projectCodeSnippet_snippetsWrapper_1Wt7mYM .projectCodeSnippet_codeCopy_cT-Udxl {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 99;
  padding: 5px 10px;
  color: #394EFF;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.projectCodeSnippet_snippetsWrapper_1Wt7mYM .projectCodeSnippet_codeCopy_cT-Udxl:hover {
  background-color: #ddd;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.projectCodeSnippet_snippetsWrapper_1Wt7mYM .projectCodeSnippet_snippet_3slB89q {
  overflow: hidden;
  line-height: 18px;
  border-radius: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.projectCodeSnippet_snippetsWrapper_1Wt7mYM .projectCodeSnippet_snippet_3slB89q > div {
  background-color: #f6f6f6 !important;
}

.projectCodeSnippet_siteInfo_2z-4F7k {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px
}

.projectCodeSnippet_siteInfo_2z-4F7k span {
  color: #394EFF;
}

.projectCodeSnippet_instructions_2dG4q1g {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 15px;
}

.projectCodeSnippet_closeButton_sZOY74p {
  margin-left: auto;
  cursor: pointer;
  padding: 5px;
}

.projectCodeSnippet_siteId_1haqog_ {
  font-weight: 500
}

.projectCodeSnippet_siteId_1haqog_ span {
  background: #f6f6f6;
  border-radius: 3px;
  padding: 2px 7px;
  font-weight: normal;
  margin-left: 4px;
  border: solid thin #eee;
}

.projectCodeSnippet_info_33DdSJL {
  padding: 5px 10px;
  background-color: #ffedd1;
}