.dashboard_header_UMQIzSt {
  padding: 0px 0 15px;
}

.dashboard_dateInput_1aKKdYm {
  width: 180px !important;
  border-radius: 3px;
  border: solid thin #ddd;
  text-align: center;
  margin-left: 10px;
  background-color: white;
  height: 38px;
  color: #333
}

.dashboard_dateInput_1aKKdYm[disabled] {
  opacity: 0.5;
}