.imageInfo_name_1TC3tJk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.imageInfo_name_1TC3tJk > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
}

.imageInfo_name_1TC3tJk .imageInfo_label_1NjHv9c {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.imageInfo_hasPreview_1HBSLFB {
  /* text-decoration: underline; */
  border-bottom: 1px dotted;
  cursor: pointer;
}

.imageInfo_imagePreview_JiVBzoT {
  max-width: 200px;
  max-height: 200px;
}

.imageInfo_imageWrapper_1_mdXU_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 40px;
  text-align: center;
  margin-right: 10px
}

.imageInfo_imageWrapper_1_mdXU_ > span {
  height: 16px;
}

.imageInfo_imageWrapper_1_mdXU_ .imageInfo_label_1NjHv9c {
  font-size: 9px;
  color: #ddd;
}

.imageInfo_popup_IYS4qBI {
  background-color: #f5f5f5 !important
}

.imageInfo_popup_IYS4qBI:before {
  background-color: #f5f5f5 !important;
}