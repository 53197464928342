.sessionList_sessionList_3a0XY4q {
  padding: 0 20px;
  background-color: #f6f6f6;
  min-height: calc(100vh - 59px);
}

.sessionList_siteWrapper_K7hwzUz {
  padding-top: 10px;
  margin-bottom: 10px;
}

.sessionList_siteHeader_MP0hd0v {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 14px;
  background-color: white;
  border-top: solid thin #f6f6f6;
  margin: -15px;
  margin-top: -10px;
  margin-bottom: 20px;
  padding: 10px;
}