.saveModal_modalHeader_wDP-oji {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.saveModal_cancelButton_3DWuC4r {
  background-color: transparent !important;
  border: solid thin transparent !important;
  color: #394EFF !important;
}

.saveModal_cancelButton_3DWuC4r:hover {
  background-color: #F6F7FF !important;
}

.saveModal_applyButton_39JIeK4 {
  background-color: white !important;
  border: solid thin #D0D4F2 !important;
  color: #394EFF !important;
}

.saveModal_applyButton_39JIeK4:hover {
  background-color: #F6F7FF !important;
}