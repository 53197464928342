.sessionMenu_header_1L5SICY {
  margin-bottom: 15px
}

.sessionMenu_header_1L5SICY .sessionMenu_label_3CAtNSu {
  text-transform: uppercase;
  color: gray;
  letter-spacing: 0.2em;
}

.sessionMenu_header_1L5SICY .sessionMenu_manageButton_3DS4onA {
  margin-left: 5px;
  font-size: 12px;
  color: #394EFF;
  cursor: pointer;
  padding: 2px 5px;
  border: solid thin transparent;
  border-radius: 3px;
  margin-bottom: -3px
}

.sessionMenu_header_1L5SICY .sessionMenu_manageButton_3DS4onA:hover {
  background-color: #ddd;
  color: #333;
}

.sessionMenu_divider_1yupIQj {
  height: 1px;
  width: 100%;
  background-color: #ddd;
}