.selectorList_wrapper_3tnQUPj {
  height: calc(100vh - 190px);
  overflow-y: auto
}

.selectorList_wrapper_3tnQUPj::-webkit-scrollbar {
  width: 2px;
  background: transparent !important;
  background: rgba(0,0,0,0);
}

.selectorList_wrapper_3tnQUPj::-webkit-scrollbar-thumb {
  background: transparent !important;
}

.selectorList_wrapper_3tnQUPj::-webkit-scrollbar-track {
  background: transparent !important;
}

.selectorList_wrapper_3tnQUPj:hover::-webkit-scrollbar {
  width: 2px;
  background: rgba(0,0,0,0.1)
}

.selectorList_wrapper_3tnQUPj:hover::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.1)
}

.selectorList_wrapper_3tnQUPj:hover::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.1)
}