.notifications_tabHeader_3f5Rc-Q {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 25px
}

.notifications_tabHeader_3f5Rc-Q .notifications_tabTitle_29QS1d2 {
  margin: 0 15px 0 0;
  font-weight: 400 !important;
}

.notifications_form_2gN1Hz5 {
  padding: 0 20px
}

.notifications_form_2gN1Hz5 .notifications_formGroup_23GPd3S {
  margin-bottom: 15px;
}

.notifications_form_2gN1Hz5 label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.notifications_form_2gN1Hz5 .notifications_input_3-VxQ5u {
  width: 100%;
}

.notifications_form_2gN1Hz5 input[type=checkbox] {
  margin-right: 10px;
  height: 13px;
}

.notifications_adminInfo_3qwU0-M {
  font-size: 12px;
  color: #888;
}