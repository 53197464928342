.marker_marker_6eSJ3jD {
  display: none;
  position: absolute;
  background: rgba(40, 40, 100, .3);
  pointer-events: none;
}

.marker_marker_6eSJ3jD div:not(.marker_tooltip_2CyKby_, .marker_tooltip_2CyKby_ > div) {
  position: absolute;
  background-image: linear-gradient(45deg, #00d 25%, #fff 25%, #fff 75%, #00d 75%, #00d),
                    linear-gradient(45deg, #00d 25%, #fff 25%, #fff 75%, #00d 75%, #00d);
  background-size: 5px 5px;
}

.marker_markerL_2doxkf9 {
  left: -1px;
  top: -100vh;
  bottom: -100vh;
  width: 1px;
}

.marker_markerT_2lFnx0D {
  top: -1px;
  left: -100vw;
  right: -100vw;
  height: 1px;
}

.marker_markerR_2nDJ2H1 {
  right: 1px;
  top: -100vh;
  bottom: -100vh;
  width: 1px;
}

.marker_markerB_3jGeoit {
  bottom: 1px;
  left: -100vw;
  right: -100vw;
  height: 1px;
}

.marker_tooltip_2CyKby_ {
  position: absolute;
  left: 0;
  bottom: 100%;
  padding: 15px;
  -webkit-box-shadow: 2px 2px 1px rgba(40, 40, 100, .3);
          box-shadow: 2px 2px 1px rgba(40, 40, 100, .3);
  z-index: 999;
  border-radius: 3px;
  background-color: #202124;
  min-width: 400px;
  font-size: 20px !important
}

.marker_tooltip_2CyKby_ div:first-child {
  max-width: 600px;
  height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marker_tooltip_2CyKby_ div:last-child {
  font-size: 18px;
  margin-top: 10px;
  color: #3EAAAF;
}