.sideMenu_wrapper_2VRqFBY {
  padding-right: 20px;
}

.sideMenu_header_Uvy7DDk {
  margin-bottom: 10px
}

.sideMenu_header_Uvy7DDk .sideMenu_label_1-lvaqA {
  text-transform: uppercase;
  color: gray;
  letter-spacing: 0.2em;
}

.sideMenu_header_Uvy7DDk .sideMenu_manageButton_3fm_jv4 {
  margin-left: 5px;
  font-size: 12px;
  color: #394EFF;
  cursor: pointer;
  padding: 2px 5px;
  border: solid thin transparent;
  border-radius: 3px;
  margin-bottom: -3px;
}

.sideMenu_header_Uvy7DDk .sideMenu_manageButton_3fm_jv4:hover {
  background-color: #ddd;
  color: #333;
}

.sideMenu_divider_1Rk6EmW {
  height: 1px;
  width: 100%;
  background-color: #ddd;
}