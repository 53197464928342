.callWithErrors_topActions_h9q2-EY {
  position: absolute;
  top: -4px;
  right: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.callWithErrors_searchField_14c3kr2 {
  padding: 4px 5px;
  border-bottom: dotted thin #ddd;
  border-radius: 3px
}

.callWithErrors_searchField_14c3kr2:focus,
  .callWithErrors_searchField_14c3kr2:active {
  border: solid thin transparent !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #ddd;
}

.callWithErrors_searchField_14c3kr2:hover {
  border: solid thin #ddd !important;
}