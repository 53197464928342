.player_playerBody_2JH3OUN {
  background: #fff;
  /* border-radius: 3px; */
  /* padding: 10px 10px 5px 10px; */
  /* box-shadow: 0px 2px 10px 0 $gray-light; */
  height: 100%;
  /* border: solid thin $gray-light; */
  border-right: solid thin #ddd;
}

.player_screenWrapper_3yfit-n {
  width: 100%;
  position: relative;
  height: 100%;
  /* border: solid thin $gray-light; */
  /* border-radius: 3px; */
  overflow: hidden;
  background: #f6f6f6;
}

.player_disconnected_300pLqr {
  font-size: 40px;
  font-weight: 200;
  color: #888;
}

.player_playerView_1tJJ_XD {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
