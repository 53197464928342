.funnelHeader_dropdown_3H3sKe4 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 20px;
  border-radius: 0;
  border-radius: 0;
  color: #333;
  font-weight: 500;
  height: 54px;
  padding-right: 20px;
  border-right: solid thin #eee;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px
}

.funnelHeader_dropdown_3H3sKe4:hover {
  background-color: #f6f6f6;
}

.funnelHeader_dropdownTrigger_2fA-jbG {
  padding: 4px 8px;
  border-radius: 3px
}

.funnelHeader_dropdownTrigger_2fA-jbG:hover {
  background-color: #ddd;
}

.funnelHeader_dropdownIcon_2sfcjxI {
  margin-top: 4px;
  margin-left: 6px;
}