.webhooks_tabHeader_1uwFAo4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 25px
}

.webhooks_tabHeader_1uwFAo4 .webhooks_tabTitle_2Npl535 {
  margin: 0 15px 0 0;
  font-weight: 400 !important;
}