.sessionCaptureRate_inputField_3HlsyhK {
  max-width: 140px !important
}

.sessionCaptureRate_inputField_3HlsyhK label {
  font-weight: 300 !important;
}

.sessionCaptureRate_inputField_3HlsyhK input {
  max-width: 70px !important;
}

.sessionCaptureRate_customSlider_3RC8S_A {
  line-height: 20px !important;
}