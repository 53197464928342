.metadataItem_field_18KOxRr:not(:last-child) {
  border-bottom: solid thin #EEEEEE;
}

.metadataItem_field_18KOxRr {
  /* padding: 10px 20px; */
}

.metadataItem_key_2QAOqEM {
  color: #888;
  font-weight: 500;
}

.metadataItem_searchResultsHeader_1br1_5z span {
  padding: 4px 8px;
  font-size: 18px;
  background-color: #f6f6f6;
  border: solid thin #ddd;
  margin-left: 10px;
  border-radius: 3px;
}

.metadataItem_searchButton_1ycM3SU  {
  border-radius: 3px;
  height: 30px !important;
  width: 30px !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  padding: 0 !important;
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important
}

.metadataItem_searchButton_1ycM3SU:hover {
  background-color: #f6f6f6 !important;
}
