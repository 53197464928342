.controlButton_controlButton_nqn_9eC {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 5px 10px;
  cursor: pointer;
  min-width: 60px;
  position: relative;
  border-radius: 3px
}

.controlButton_controlButton_nqn_9eC.controlButton_active_B8IO7Y5, .controlButton_controlButton_nqn_9eC:hover {
  background-color: #f6f6f6;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.controlButton_controlButton_nqn_9eC .controlButton_errorSymbol_2VExflz {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: red;
  top: 12px;
  left: 23px;
  position: absolute;
}

.controlButton_controlButton_nqn_9eC .controlButton_countLabel_1QbKvBg {
  position: absolute;
  top: -6px;
  left: 12px;
  background-color: #666;
  color: white;
  font-size: 9px;
  font-weight: 300;
  min-width: 20px;
  height: 16px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 3px;
}

.controlButton_controlButton_nqn_9eC .controlButton_label_3tzEdL7 {
  /* padding-top: 5px; */
  font-size: 10px;
  color: #333;
  height: 16px;
}

.controlButton_controlButton_nqn_9eC.controlButton_disabled_1Zy_uFo {
  pointer-events: none;
  opacity: 0.5;
}