.SelectorCard_wrapper_1lcSKxN {
  padding: 10px;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-color: white;
  margin-bottom: 15px
}

.SelectorCard_wrapper_1lcSKxN .SelectorCard_top_2uI1f2Y {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.SelectorCard_wrapper_1lcSKxN .SelectorCard_index_3dDwnbt {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #3EAAAF;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border: solid thin white;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
  color: white;
}

.SelectorCard_wrapper_1lcSKxN .SelectorCard_counts_UvzDILZ {
  text-align: center;
  padding: 5px;
  margin: 20px 0
}

.SelectorCard_wrapper_1lcSKxN .SelectorCard_counts_UvzDILZ div:first-child {
  font-size: 18px;
  margin-bottom: 5px;
}

.SelectorCard_active_6wMlrAR {
  background-color: #f9ffff;
}