.storage_button_Or7nnAR {
  padding: 2px 6px;
  cursor: pointer;
  width: 60px;
  border-radius: 3px;
  color: #ddd;
}

.storage_button_Or7nnAR:hover {
  background-color: #f6f6f6;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  color: #333;
}

.storage_button_Or7nnAR {
  font-size: 12px;
  margin-right: 5px;
}