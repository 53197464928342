.event_contextMenu_2JpSAqT {
  position: absolute;
  top: 27px;
  right: 15px;
  padding: 2px 3px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
  color: #888;
  font-size: 12px;
  z-index: 2;
}

.event_event_om9ssmo {
  position: relative;
  background: #fff;
  border-radius: 3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* box-shadow: 0px 1px 3px 0 $gray-light; */
  border: 1px solid #EEE;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer
}

.event_event_om9ssmo:hover {
  background-color: #F6F7FF;
  border: 1px solid #D0D4F2;
}

.event_event_om9ssmo .event_title_1d_zpa0 {
  font-size: 13px;
}

.event_event_om9ssmo .event_topBlock_3Q0Eq_k {
  min-height: 30px;
  position: relative;
  padding: 8px 10px;
}

.event_event_om9ssmo .event_checkbox_3daadS- {
  position: absolute;
  left: 10px;
  top: 8px;
  bottom: 0;
  /* margin: auto; */
  display: none;
  /* align-items: center; */
}

.event_event_om9ssmo.event_menuClosed_18JucNC:hover .event_edit_XiqF4J6 {
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.event_event_om9ssmo.event_menuClosed_18JucNC.event_showSelection_R65Noq2:hover, .event_event_om9ssmo.event_menuClosed_18JucNC.event_showSelection_R65Noq2.event_selected_74nxWdw {
  background-color: #EFFCFB;
}

.event_event_om9ssmo.event_menuClosed_18JucNC.event_showSelection_R65Noq2:hover .event_checkbox_3daadS-, .event_event_om9ssmo.event_menuClosed_18JucNC.event_showSelection_R65Noq2.event_selected_74nxWdw .event_checkbox_3daadS- {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.event_event_om9ssmo.event_menuClosed_18JucNC.event_showSelection_R65Noq2:hover .event_icon_BkBtLG-, .event_event_om9ssmo.event_menuClosed_18JucNC.event_showSelection_R65Noq2.event_selected_74nxWdw .event_icon_BkBtLG- {
  opacity: 0;
}

.event_event_om9ssmo.event_highlighted_pqFopVp {
  background-color: #F6F7FF;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-box-shadow: 0px 2px 10px 0 #ddd;
          box-shadow: 0px 2px 10px 0 #ddd;
  border-color: #D0D4F2;
}

.event_event_om9ssmo.event_red_2XdqAbv {
  border-color: #cc0000;
}

.event_firstLine_1iQIfWv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.event_main_2_jEVGA {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
}

.event_type_1yQn4Ml {
  color: #666;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: bold;
}

.event_data_2v_qYWl {
  margin-left: 5px;
  color: #888;
  font-size: 12px;
  max-width: 100%;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}

.event_badge_3XZA6zt {
  display: inline-block;
  padding: 0;
  border-radius: 3px;
  font-size: 9px;
  /* margin-left: 28px; */
  max-width: 170px;
  word-wrap: break-word;
  line-height: normal;
  color: #999;
  text-transform: none;
}

.event_icon_BkBtLG- {
  margin-right: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center
}

.event_icon_BkBtLG- i {
  width: 18px;
  height: 18px;
}

.event_clickType_25e2mZB, .event_inputType_1bdxVUh {
  /* border: 1px solid $gray-light; */
  background-color: #f6f6f6;
  cursor: pointer;
}

.event_clickrageType_3MdgjZu {
  background-color: #FFF3F3;
  border: 1px solid #CC0000;
  -webkit-box-shadow:
    /* The top layer shadow */
    /* 0 1px 1px rgba(0,0,0,0.15), */
    /* The second layer */
    2px 2px 1px 1px white,
    /* The second layer shadow */
    2px 2px 0px 1px rgba(0,0,0,0.4);
          box-shadow:
    /* The top layer shadow */
    /* 0 1px 1px rgba(0,0,0,0.15), */
    /* The second layer */
    2px 2px 1px 1px white,
    /* The second layer shadow */
    2px 2px 0px 1px rgba(0,0,0,0.4);
  /* Padding for demo purposes */
  /* padding: 12px; */
}

.event_highlight_I3qGiMf {
  border: solid thin red;
}