.Log_line_DpKQorb {
  padding: 7px 0 7px 15px;
}

.Log_message_288P1YZ {
  font-size: 13px
}

.Log_message_288P1YZ::-webkit-scrollbar {
  height: 2px;
}
