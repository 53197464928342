.roleForm_form_3DPKZ9i {
  padding: 0 20px
}

.roleForm_form_3DPKZ9i .roleForm_formGroup_3rC6l8O {
  margin-bottom: 15px;
}

.roleForm_form_3DPKZ9i label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.roleForm_form_3DPKZ9i .roleForm_input_O_ppuQp {
  width: 100%;
}

.roleForm_form_3DPKZ9i input[type=checkbox] {
  margin-right: 10px;
  height: 13px;
}