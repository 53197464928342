.header_header_ZT8DtHv {
  height: 50px;
  border-bottom: solid thin #ddd;
  padding: 10px 15px;
  background-color: white;
}

.header_divider_3KEfU6H {
  width: 1px;
  height: 100%;
  margin: 0 15px;
  background-color: #ddd;
}

