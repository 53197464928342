.autoComplete_menu_3rCfDnS {
  border-radius: 0 0 3px 3px;
  -webkit-box-shadow: 0 2px 10px 0 #ddd;
          box-shadow: 0 2px 10px 0 #ddd;
  padding: 20px;
  background-color: white;
  max-height: 350px;
  overflow-y: auto;
  position: absolute;
  top: 28px;
  left: 0;
  width: 500px;
  z-index: 99;
}

.autoComplete_searchInput_16toz_T input {
  font-size: 13px !important;
  padding: 5px !important;
  color: #333 !important;
  font-size: 14px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.autoComplete_searchInput_16toz_T {
  height: 28px !important;
  width: 280px;
  color: #333 !important;
}

.autoComplete_fullWidth_3h9sQhI {
  width: 100% !important;
}
