.addWidgets_widgetCard_-tCfGZK {
  min-height: 110px;
  padding: 15px;
  width: 100%;
  border: 1px solid #ddd;
  border-bottom: none
}

.addWidgets_widgetCard_-tCfGZK:last-child {
  border-bottom: 1px solid #ddd;
}

.addWidgets_widgetCard_-tCfGZK h4 {
  margin-bottom: 10px;
}

.addWidgets_widgetCard_-tCfGZK p {
  color: #888;
  font-weight: 300;
  font-size: 12px;
}

.addWidgets_thumb_CUUUxnt {
  border: solid thin #ddd;
  margin-right: 10px;
  width: 170px;
}

.addWidgets_menuWrapper_35W534H {
  max-height: 300px;
  overflow-y: auto
}

.addWidgets_menuWrapper_35W534H::-webkit-scrollbar {
  width: 2px;
}

.addWidgets_menuItem_1qDJy6f {
  -webkit-transition: all .2s;
  transition: all .2s;
  border-bottom: solid thin #ddd;
  padding: 8px 10px;
  overflow: hidden;
  text-overflow: ellipsis
}

.addWidgets_menuItem_1qDJy6f:last-child {
  border-bottom: none;
}

.addWidgets_menuItem_1qDJy6f:hover {
  -webkit-transition: all .4s;
  transition: all .4s;
  background-color: #f6f6f6;
}