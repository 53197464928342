.roles_wrapper_1eJ9PbN {
  padding: 0;
}

.roles_tabHeader_2NTCDjP {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 25px
}

.roles_tabHeader_2NTCDjP .roles_tabTitle_2YUtUMO {
  margin: 0 15px 0 0;
  font-weight: 400 !important;
}