.fetchDetails_url_3X_-N5q {
  padding: 10px;
  border-radius: 3px;
  background-color: #f6f6f6;
  /* border: solid thin $gray-light; */
  /* max-width: 90%; */
  word-break: break-all;
  max-height: 300px;
  overflow-y: auto;
}

.fetchDetails_status_1Dy7lmu {
  padding: 3px 8px;
  border-radius: 12px;
  /*border: 1px solid $gray-light;*/
}