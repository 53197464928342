@-webkit-keyframes controls_fade_3agmwWR {
  0%   { opacity: 1
  }

  50% { opacity: 0
  }

  100%   { opacity: 1
  }
}

@keyframes controls_fade_3agmwWR {
  0%   { opacity: 1
  }

  50% { opacity: 0
  }

  100%   { opacity: 1
  }
}

.controls_controls_1D0qMlX {
  /* margin-top: 10px; */
  border-top: solid thin #ddd;
  padding-top: 36px;
  padding-bottom: 10px;
}

.controls_buttons_1wiIs86 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 7px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 30px
}

.controls_buttons_1wiIs86[data-is-live=true] {
  padding: 0;
}

.controls_buttonsLeft_GWzxVNE {
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.controls_butonsRight_2UaR1Z- {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.controls_speedButton_2hPU-Em {
  cursor: pointer;
  color: #333;
  font-size: 14px;
  padding: 0 10px;
  height: 30px;
  border-radius: 3px
}

.controls_speedButton_2hPU-Em:hover {
  background-color: #f6f6f6;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.controls_skipIntervalButton_r0LMAhe {
  font-weight: normal !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  font-size: 12px;
  color: #333;
  /* margin-right: 5px; */
  padding: 0 10px;
  height: 30px;
  border-radius: 3px
  /* margin: 0 5px; */
}

.controls_skipIntervalButton_r0LMAhe:hover {
  background-color: #f6f6f6;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.controls_divider_3l5lShv {
  height: 30px;
  width: 1px;
  margin: 0 5px;
  background-color: #EEEEEE;
}

.controls_withCheckIcon_10_-1oT {
  color: #333
}

.controls_withCheckIcon_10_-1oT .controls_checkIcon_34Uvv7g {
  /* opacity: 1 !important; */
  display: block;
}

.controls_checkIcon_34Uvv7g {
  width: 12px;
  height: 12px;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-check' viewBox='0 0 16 16' fill='%23666'%3E %3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-right: 8px;
  display: none;
}

.controls_liveTag_2CmVvDv {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 26px;
  width: 56px;
  border-radius: 3px;
  background-color: #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #666;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  margin-right: 10px
}

.controls_liveTag_2CmVvDv svg {
  fill: #666;
}

.controls_liveTag_2CmVvDv[data-is-live=true] {
  background-color: #42AE5E;
  color: white
}

.controls_liveTag_2CmVvDv[data-is-live=true] svg {
  fill: white;
  -webkit-animation: controls_fade_3agmwWR 1s infinite;
          animation: controls_fade_3agmwWR 1s infinite;
}