.customFields_tabHeader_19DOuaQ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 25px
}

.customFields_tabHeader_19DOuaQ .customFields_tabTitle_36vQrBw {
  margin: 0 15px 0 0;
  font-weight: 400 !important;
}