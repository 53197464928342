.filterItem_wrapper_3AS0mdE {
  min-height: 38px;
}

.filterItem_dropdown_6Rx7ONU {
  display: 'flex' !important;
  -webkit-box-align: 'center';
      -ms-flex-align: 'center';
          align-items: 'center';
  padding: 5px 8px;
  border-radius: 3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500
}

.filterItem_dropdown_6Rx7ONU:hover {
  background-color: #DDDDDD;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.filterItem_circle_2mKMVmm {
  width: 15px;
  height: 15px;
  background-color: #3EAAAF;
  border-radius: 50%;
  margin-right: 10px
}

.filterItem_circle_2mKMVmm.filterItem_compare_P0QOw-F {
  background-color: #394EFF;
}