.timeline_progress_13ltDcf {
  height: 10px;
  border-radius: 1px;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

.timeline_timeline_2g-41HQ {
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ddd;
}

.timeline_event_1AxHoYX {
  position: absolute;
  width: 8px;
  height: 8px;
  border: solid 1px white;
  margin-left: -4px;
  border-radius: 50%;
  background: rgba(136, 136, 136, 0.8);
  pointer-events: none;
}

.timeline_popup_1vmIUEA {
  max-width: 300px !important;
  overflow: hidden;
  text-overflow: ellipsis
}

.timeline_popup_1vmIUEA span {
  display: block;
  max-height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.timeline_markup_vtpjD9D {
  position: absolute;
  width: 2px;
  height: 8px;
  border-radius: 2px;
  margin-left: -1px;
}

.timeline_markup_vtpjD9D.timeline_log_Mol0Vpo {
  background: #366CD9;
}

.timeline_markup_vtpjD9D.timeline_error_1ysuzkI {
  background: #cc0000;
}

.timeline_markup_vtpjD9D.timeline_warning_1w0flzp {
  background: #E28940;
}

.timeline_markup_vtpjD9D.timeline_info_eZsPKS4 {
  background: #0076FF;
}