.eventGroupWrapper_container_1DWjETX {
  padding: 5px 7px; /*0.35rem 0.5rem */
  border-right: solid thin #E0E0E0;
  border-left: solid thin #E0E0E0;
  /* border: solid thin #E0E0E0; */
  /* background-color: rgba(255, 255, 255, 0.5); */
  background-color: white;
}

.eventGroupWrapper_first_33ept7O {
  padding-top: 7px;
  border-top: solid thin #E0E0E0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.eventGroupWrapper_last_IQ1We_n {
  padding-bottom: 7px;
  border-bottom: solid thin #E0E0E0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.eventGroupWrapper_dashAfter_1FTnkSD {
  position: relative;
  margin-bottom: 0.8rem
}

.eventGroupWrapper_dashAfter_1FTnkSD:after {
  height: 0.9rem;
  width: 2px;
  background-color: #E0E0E0;
  content: '';
  position: absolute;
  left: 30px;
  top: 100%;
}

.eventGroupWrapper_referrer_JRjPhzD {
  font-size: 14px;
  margin-bottom: 10px;
  color: #666;
  font-weight: 500 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.eventGroupWrapper_referrer_JRjPhzD .eventGroupWrapper_url_2VPcgxH {
  margin-left: 5px;
  font-weight: 300;
  color: #888;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}