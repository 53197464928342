.trackingCodeModal_modalHeader_2rzUvx7 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.trackingCodeModal_content_1v-HOFv {
  background-color: white !important;
  padding: 0 !important;
}

.trackingCodeModal_closeButton_29U_mGp {
  margin-left: auto;
  cursor: pointer;
  padding: 5px;
}

