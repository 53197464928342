.filterSelectionButton_wrapper_3asjaf8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 28px;
  border: solid thin rgba(34, 36, 38, 0.15) !important;
  border-radius: 4px;
  padding: 0 10px;
  width: 150px;
  color: #333;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8) !important
}

.filterSelectionButton_wrapper_3asjaf8:hover {
  background-color: white;
}

.filterSelectionButton_wrapper_3asjaf8 span {
  margin-right: 5px;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}