.timeTracker_positionTracker_2dnrxjJ {
  width: 15px;
  height: 15px;
  border: solid 1px #394EFF;
  margin-left: -7px;
  border-radius: 50%;
  background-color: #F6F7FF;
  position: absolute;
  left: 0;
  z-index: 98;
  pointer-events: none; /* temporary. DnD should be */
}

.timeTracker_playedTimeline_RcSTYDS {
  height: 100%;
  border-radius: 4px;
  background-color: #394EFF;
  pointer-events: none;
  height: 2px;
  z-index: 1;
}