.chatWindow_wrapper_1CUVP1r {
  background-color: white;
  border: solid thin #000;
  border-radius: 3px;
  position: fixed;
  width: 300px;
}

.chatWindow_headerTitle_1mHSyqQ {
  font-size: 12px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chatWindow_videoWrapper_3W1xjNZ {
  height: 180px;
  overflow: hidden;
  background-color: #000;
}