.sessionStack_wrapper_1oJJZa3 {
  background: #fff;
  border: solid thin #ddd;
  border-radius: 3px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.sessionStack_wrapper_1oJJZa3:hover {
  background-color: #F6F7FF;
  border: solid thin #D0D4F2;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.sessionStack_wrapper_1oJJZa3 {
  -webkit-box-shadow:
    /* The top layer shadow */
    /* 0 1px 1px rgba(0,0,0,0.15), */
    /* The second layer */
    4px 4px 1px 1px white,
    
    4px 4px 0px 1px rgba(0,0,0,0.4);
          box-shadow:
    /* The top layer shadow */
    /* 0 1px 1px rgba(0,0,0,0.15), */
    /* The second layer */
    4px 4px 1px 1px white,
    
    4px 4px 0px 1px rgba(0,0,0,0.4);
  /* Padding for demo purposes */
  padding: 16px;
}