.inspector_wrapper_1ob3HQM {
  height: 290px;
  overflow-y: auto;
  padding: 5px;
}

.inspector_bgHighlight_1HTXh1x {
  background-color: rgba(0, 0, 205, 0.2);
}

.inspector_tag_1RsxzuU {
  color: #044289;
}

.inspector_attributeName_3Iowdep {
  color: #2188ff;
}

.inspector_attributeValue_2zh5u0q {
  color: #d73a49;
}

.inspector_attributeInput_36yUCsz {
  max-width: 75% !important;
}