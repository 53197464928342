.bugFinder_searchWrapper_3V98zao {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-radius: 3px;
  margin-bottom: 30px;
}

.bugFinder_bottom_3SBJs2c {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: solid thin #EDEDED
}

.bugFinder_bottom_3SBJs2c > div {
  cursor: pointer;
  padding: 0 10px;
  border-right: solid thin #ddd;
}

.bugFinder_bottom_3SBJs2c > div:hover {
  background-color: #F6F7FF;
}

.bugFinder_bottom_3SBJs2c > div:last-child {
  border-right: solid thin transparent;
}

.bugFinder_bottom_3SBJs2c > div:first-child {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
}

.bugFinder_savedSearchesWrapper_3z76Tw8 {
  width: 200px;
  margin-left: 20px;
}

.bugFinder_header_m7fYsQ_ {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  color: #888;
}