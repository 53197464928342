.eventEditor_wrapper_27xsUop {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 15px;
  background-color: white;
  border-bottom: solid thin #f6f6f6;
  -webkit-transition: all 0.4s;
  transition: all 0.4s
}

.eventEditor_wrapper_27xsUop:last-child {
  border-bottom: solid thin transparent;
}

.eventEditor_wrapper_27xsUop:hover {
  background-color: #F6F7FF;
  -webkit-transition: all 0.2s;
  transition: all 0.2s
}

.eventEditor_wrapper_27xsUop:hover .eventEditor_actions_3IiDuTT {
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.eventEditor_wrapper_27xsUop .eventEditor_leftSection_3OZ0XNO,
  .eventEditor_wrapper_27xsUop .eventEditor_actions_3IiDuTT {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.eventEditor_wrapper_27xsUop .eventEditor_leftSection_3OZ0XNO {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1
}

.eventEditor_wrapper_27xsUop .eventEditor_leftSection_3OZ0XNO > div {
  margin-right: 10px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.eventEditor_index_3Xeg1QF {
  background: #fff;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
  color: #888;
  font-weight: 300;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

.eventEditor_button_1FQeQmM {
  width: 25px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  margin-left: 10px;
}

.eventEditor_actions_3IiDuTT {
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}