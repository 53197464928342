.sortDropdown_dropdown_3lO6Sac {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 4px 6px;
  border-radius: 3px;
  color: #333;
  font-weight: 500
}

.sortDropdown_dropdown_3lO6Sac:hover {
  background-color: #ddd;
}

.sortDropdown_dropdownTrigger_T4AS8W6 {
  padding: 4px 8px;
  border-radius: 3px
}

.sortDropdown_dropdownTrigger_T4AS8W6:hover {
  background-color: #ddd;
}

.sortDropdown_dropdownIcon_3PWXio4 {
  margin-top: 2px;
  margin-left: 3px;
}