.sentry_wrapper_3K05zGM {
  padding: 20px 40px 30px;
}

.sentry_icon_3Nu2BSB {
  margin-left: -5px;
}

.sentry_stacktrace_WO8fCm2 h6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 17px;
  padding-top: 7px;
  margin-bottom: 10px;
}

.sentry_stacktrace_WO8fCm2 p {
  font-family: 'Menlo', 'monaco', 'consolas', monospace;
}

.sentry_accordionTitle_1nJjQgU {
  font-weight: 100
}

.sentry_accordionTitle_1nJjQgU > b {
  font-weight: 700;
}

.sentry_lineList_1fhzWk4 {
  list-style-position: inside;
  list-style-type: decimal-leading-zero;
  background: #f6f6f6;
}

.sentry_codeLine_1fph1YG {
  font-family: 'Menlo', 'monaco', 'consolas', monospace;
  line-height: 24px;
  font-size: 12px;
  white-space: pre-wrap;
  word-wrap: break-word;
  min-height: 24px;
  padding: 0 25px
}

.sentry_codeLine_1fph1YG.sentry_highlighted_nkzuI9o {
  background: #cc0000;
  color: #fff;
}