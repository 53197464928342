.barRow_barWrapper_2i0Xy_j {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 35%;
  bottom: 35%;
  border-radius: 3px;
  overflow: hidden;
}

.barRow_downloadBar_1V938IB, .barRow_ttfbBar_KFRNyk3 {
  /* box-shadow: inset 0px 0px 0px 1px $teal; */
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}

.barRow_ttfbBar_KFRNyk3 {
  background-color: rgba(175, 226, 221, 0.8);
}

.barRow_downloadBar_1V938IB {
  background-color: rgba(133, 200, 192, 0.8);
}

.barRow_popupRow_3W2gnVA {
  color: #888;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2px 0;
  font-size: 12px;
}

.barRow_title_1lPqIK4 {
  width: 105px;
}

.barRow_time_2b5a6PO {
  width: 60px;
  padding-left: 10px;
}

.barRow_popupBarWrapper_2amfmFl {
  width: 220px;
  height: 15px;
  border-radius: 3px;
  overflow: hidden;
}