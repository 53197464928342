.playerBlockHeader_header_1d_u7IO {
  height: 50px;
  border-bottom: solid thin #ddd;
  padding: 10px 15px;
  background-color: white;
}

.playerBlockHeader_divider_3cW-h6V {
  width: 1px;
  height: 100%;
  margin: 0 15px;
  background-color: #ddd;
}

.playerBlockHeader_liveSwitchButton_n2V1G8A {
  cursor: pointer;
  padding: 3px 8px;
  border: solid thin #42AE5E;
  color: #42AE5E;
  border-radius: 3px;
  margin-right: 10px
}

.playerBlockHeader_liveSwitchButton_n2V1G8A:hover {
  background-color: #42AE5E;
  color: white;
}