.client_wrapper_1lSnuCB {
  margin-top: -30px;
}

.client_main_2o-4WK7 {
  max-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: calc(100vh - 81px)
}

.client_main_2o-4WK7 .client_tabMenu_2Bh6IxY {
  width: 240px;
  margin: 0;
  background-color: #f6f6f6;
}

.client_main_2o-4WK7 .client_tabContent_hLwNRHH {
  background-color: white;
  padding: 25px;
  margin-top: -30px;
  margin-right: -20px;
  width: 100%;
}

.client_form_2IsdNKD {
  width: 350px
}

.client_form_2IsdNKD .client_formGroup_2eNrVgx {
  margin-bottom: 25px;
}

.client_form_2IsdNKD .client_formGroup_2eNrVgx .client_label_1oFGqyg {
  margin-bottom: 3px;
  font-weight: 500;
}

.client_form_2IsdNKD p {
  margin: 0;
  margin-top: 10px;
}

.client_form_2IsdNKD input {
  display: inline-block;
  width: 100%;
  border: #ddd solid 1px;
  border-radius: 4px;
  background: #fff;
  padding: 10px 8px !important;
}

.client_form_2IsdNKD input:focus {
  border-color: #394EFF !important;
}

.client_form_2IsdNKD .client_submit_2FnVFVr {
  margin-top: 20px;
}

.client_form_2IsdNKD:after {
  content: '';
  display: block;
  clear: both;
}

