.imageInfo_name_1-rwFeo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.imageInfo_name_1-rwFeo > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
}

.imageInfo_imagePreview_1JsEq_i {
  max-width: 200px;
  max-height: 200px;
}

.imageInfo_imageWrapper_9ebHNij {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 40px;
  text-align: center;
  margin-right: 10px
}

.imageInfo_imageWrapper_9ebHNij > span {
  height: 16px;
}

.imageInfo_imageWrapper_9ebHNij .imageInfo_label_3UCPk0A {
  font-size: 9px;
  color: #ddd;
}

.imageInfo_popup_2dRADhZ {
  background-color: #f5f5f5 !important
}

.imageInfo_popup_2dRADhZ:before {
  background-color: #f5f5f5 !important;
}