.onboardingMenu_stepWrapper_vQgyiQi {
  overflow: hidden;
  position: relative
}

.onboardingMenu_stepWrapper_vQgyiQi .onboardingMenu_step_2vdKI2H {
  padding: 10px 0
}

.onboardingMenu_stepWrapper_vQgyiQi .onboardingMenu_step_2vdKI2H .onboardingMenu_iconWrapper_3jk_IjG {
  background-color: white;
  border: solid thin #ddd;
}

.onboardingMenu_stepWrapper_vQgyiQi.onboardingMenu_completed_yBacOwT .onboardingMenu_step_2vdKI2H .onboardingMenu_iconWrapper_3jk_IjG {
  background-color: #888;
}

.onboardingMenu_stepWrapper_vQgyiQi.onboardingMenu_active_xPdqw0z .onboardingMenu_step_2vdKI2H .onboardingMenu_iconWrapper_3jk_IjG {
  background-color: #394EFF;
}

.onboardingMenu_stepWrapper_vQgyiQi:first-child .onboardingMenu_verticleLine_29MJXnD:before {
  display: none !important;
}

.onboardingMenu_stepWrapper_vQgyiQi:last-child .onboardingMenu_verticleLine_29MJXnD:after {
  display: none !important;
}

.onboardingMenu_verticleLine_29MJXnD:before, .onboardingMenu_verticleLine_29MJXnD:after {
  content: "";
  width: 1px;
  position: absolute;
  background-color: #888;
  left: 10px;
}

.onboardingMenu_verticleLine_29MJXnD:before {
  top: 0;
  bottom: 75%;
}

.onboardingMenu_verticleLine_29MJXnD:after {
  top: 75%;
  bottom: 0;
}