.integrations_tabHeader_2pbkUQz {
  margin-bottom: 25px
}

.integrations_tabHeader_2pbkUQz .integrations_tabTitle_2IlW8Fq {
  margin: 0 15px 0 0;
  font-weight: 400 !important;
}

.integrations_tabHeader_2pbkUQz .integrations_subText_refYb2q {
  font-weight: 300;
  color: #888;
  padding: 5px 0;
}

.integrations_tabHeader_2pbkUQz .integrations_divider_jEiKPHY {
  height: 1px;
  background-color: #DDDDDD;
}

.integrations_sectionTitle_3eVK5zh {
  margin: 10px 15px 15px 0;
  font-weight: 400 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.integrations_sectionTitle_3eVK5zh>span:first-child {
  padding-right: 10px;
}

.integrations_message_18kA4i7 {
  color: #ddd;
  display: inline;
  padding-left: 20px;
}

.integrations_applicable_FlCt72V {
  padding-left: 15px;
  font-size: 11px;
  color: #ddd;
}

.integrations_requestNew_703-_zJ {
  min-width: 135px;
  color: #888;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  text-decoration: underline;
}

.integrations_content_32J7dI3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap
}

.integrations_content_32J7dI3 > div {
  margin-bottom: 20px;
  margin-right: 20px;
}