.randomPlaceholder_placeholder_3CSat_L {
  color: #888;
  font-weight: 300;
  font-size: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.randomPlaceholder_placeholder_3CSat_L span {
  font-weight: 400;
  color: #394EFF;
  cursor: pointer;
  border-bottom: dashed thin #394EFF
}

.randomPlaceholder_placeholder_3CSat_L span:hover {
  color: #2331A8;
}