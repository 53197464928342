.filterModal_modal_GxUf_V1 {
  position: absolute;
  left: 0;
  background-color: white;
  min-width: 705px;
  max-width: calc(100vw - 500px);
  border-radius: 3px;
  border: solid thin #ddd;
  -webkit-box-shadow: 0 2px 10px 0 #ddd;
          box-shadow: 0 2px 10px 0 #ddd;
  z-index: 99;
  padding: 20px;
}

.filterModal_hint_34JcFza {
  color: #ddd;
  font-size: 12px;
  padding-bottom: 5px;
}

h5.filterModal_title_3VuP6cB {
  margin: 10px 0 3px;
}

.filterModal_filterListDynamic_3JhyC1F {
  max-height: 350px;
  overflow-y: auto
}

.filterModal_filterListDynamic_3JhyC1F::-webkit-scrollbar {
  width: 2px;
}

.filterModal_filterListDynamic_3JhyC1F::-webkit-scrollbar-thumb {
  background: transparent;
}

.filterModal_filterListDynamic_3JhyC1F::-webkit-scrollbar-track {
  background: transparent;
}

.filterModal_filterListDynamic_3JhyC1F:hover::-webkit-scrollbar-track {
  background: #f3f3f3;
}

.filterModal_filterListDynamic_3JhyC1F:hover::-webkit-scrollbar-thumb {
  background: #888;
}

.filterModal_filterListDynamic_3JhyC1F .filterModal_header_1jCRodU {
  margin-bottom: 10px;
  font-size: 13px;
  color: #596764;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-align: left;
}

.filterModal_filterListDynamic_3JhyC1F .filterModal_list_33T2t1R {
  margin-left: -8px;
}

.filterModal_filterListStatic_1nfZJOV {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 33rem;
  min-height: 20px;
  color: #888
}

.filterModal_filterListStatic_1nfZJOV .filterModal_header_1jCRodU {
  margin-bottom: 10px;
  font-size: 13px;
  color: #596764;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-align: left;
}

.filterModal_filterListStatic_1nfZJOV .filterModal_list_33T2t1R {
  margin-left: -8px;
}

.filterModal_filterListStatic_1nfZJOV .filterModal_filterGroup_2gOG9XO {
  width: 205px;
}