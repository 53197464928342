.bookmark_favoriteWrapper_dAPsSGv {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* opacity: 0; */
  margin: 0 15px
}

.bookmark_favoriteWrapper_dAPsSGv[data-favourite=true] {
  opacity: 1
}

.bookmark_favoriteWrapper_dAPsSGv[data-favourite=true] svg {
  fill: #394EFF;
}