.loadInfo_bar_17HDyeY {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  cursor: pointer
  /* margin: 0 -11px;
  margin-bottom: -9px; */
}

.loadInfo_bar_17HDyeY div {
  height: 5px;
}

.loadInfo_bar_17HDyeY div:nth-child(1) {
  background-color: #C5E6E7;
}

.loadInfo_bar_17HDyeY div:nth-child(2) {
  background-color: #8BCCCF;
}

.loadInfo_bar_17HDyeY div:nth-child(3) {
  background-color :rgba(62, 170, 175, 1);
}

.loadInfo_bottomBlock_3tUrJuP {
  overflow: hidden;
}

.loadInfo_wrapper_StPU1ye {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 12px 5px 12px 34px;
  font-size: 13px
  /* font-weight: 500; */
}

.loadInfo_wrapper_StPU1ye .loadInfo_lines_2cPQZkP {
  border-bottom: 1px solid #ddd;
  border-left: 2px solid;
  position: absolute;
  height: 100%;
  top: -21px;
  left: 14px;
  width: 15px;
}

.loadInfo_wrapper_StPU1ye .loadInfo_lines_2cPQZkP:before {
  content: "";
  border-radius: 5px;
  border: 5px solid;
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -5px;
  left: -6px;
  z-index: 1;
  /* in context */
}

.loadInfo_wrapper_StPU1ye:nth-child(1) {
  /* overflow: hidden; */
}

.loadInfo_wrapper_StPU1ye:nth-child(1) .loadInfo_lines_2cPQZkP {
  border-left-color:  #C5E6E7;
}

.loadInfo_wrapper_StPU1ye:nth-child(1) .loadInfo_lines_2cPQZkP:before {
  border-color: #C5E6E7;
}

.loadInfo_wrapper_StPU1ye:nth-child(2) .loadInfo_lines_2cPQZkP {
  border-left-color:  #8BCCCF;
}

.loadInfo_wrapper_StPU1ye:nth-child(2) .loadInfo_lines_2cPQZkP:before {
  border-color: #8BCCCF;
}

.loadInfo_wrapper_StPU1ye:nth-child(3) .loadInfo_lines_2cPQZkP {
  border-left-color:  rgba(62, 170, 175, 1);
}

.loadInfo_wrapper_StPU1ye:nth-child(3) .loadInfo_lines_2cPQZkP:before {
  border-color: rgba(62, 170, 175, 1);
}

.loadInfo_value_29HfLkc {
  font-weight: 500;
  color: #888;
}

.loadInfo_download_1i6vLO1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}