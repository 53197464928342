.durationFilter_wrapper_sbfHjpL {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between
}

.durationFilter_wrapper_sbfHjpL input {
  max-width: 85px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #888 !important;
}

.durationFilter_wrapper_sbfHjpL > div:first-child {
  margin-right: 10px;
}

.durationFilter_label_2udN0xt {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #888 !important;
}

