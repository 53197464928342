.autoscroll_wrapper_LhuRlyN .autoscroll_navButtons_2t7Trot {
  opacity: 0;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}

.autoscroll_wrapper_LhuRlyN:hover .autoscroll_navButtons_2t7Trot {
  opacity: .7;
}

.autoscroll_navButtons_2t7Trot {
  position: absolute;
  right: 260px;
  top: -34px;
}


