.widgetHolder_wrapper_2vpzudt {
  border: dotted 2px transparent;
  border-radius: 3px;
  margin: -5px;
  padding: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s
}

.widgetHolder_wrapper_2vpzudt:hover {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border: dotted 2px #888;
}