.headers_row_2FcHawk {
  /* display: flex; */
  padding: 5px 0px;
  font-size: 13px;
  word-break: break-all
  /* padding-left: 20px; */
}

.headers_row_2FcHawk:hover {
  background-color: #F6F7FF;
}

.headers_row_2FcHawk div:last-child  {
  max-width: 80%;
}