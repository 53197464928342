.eventsBlock_eventsBlock_3PcGSnp {
  width: 270px;
  /* padding: 0 10px; */
  margin-bottom: 5px;
}

.eventsBlock_header_2BABAi4 {
  /* height: 40px; */
  /* margin-bottom: 15px; */
  padding-left: 2px
  /* padding-right: 0px; */
}

.eventsBlock_header_2BABAi4 .eventsBlock_hAndProgress_1MKzIBA {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* margin-bottom: 5px; */
  /* height: 40px; */
}

.eventsBlock_header_2BABAi4 .eventsBlock_hAndProgress_1MKzIBA .eventsBlock_progress_3koaye7 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 0 0 0 15px;
}

.eventsBlock_header_2BABAi4 .eventsBlock_hAndProgress_1MKzIBA .eventsBlock_progress_3koaye7 .bar {
  background: #ffcc99;
}

.eventsBlock_header_2BABAi4 .eventsBlock_hAndProgress_1MKzIBA .eventsBlock_progress_3koaye7 .progress {
  font-size: 9px;
}

.eventsBlock_header_2BABAi4 h5 {
  margin: 0;
  /* get rid of semantic, please */
  font-size: 14px;
  font-weight: 700;
}

.eventsBlock_eventsList_3SGId3W {
  /* box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1); */
  /* border-top: solid thin $gray-light-shade; */
}

.eventsBlock_eventsList_3SGId3W::-webkit-scrollbar {
  width: 2px;
  background: transparent !important;
  background: rgba(0,0,0,0);
}

.eventsBlock_eventsList_3SGId3W::-webkit-scrollbar-thumb {
  background: transparent !important;
}

.eventsBlock_eventsList_3SGId3W::-webkit-scrollbar-track {
  background: transparent !important;
}

.eventsBlock_eventsList_3SGId3W:hover::-webkit-scrollbar {
  width: 2px;
  background: rgba(0,0,0,0.1);
}

.eventsBlock_eventsList_3SGId3W:hover::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.1);
}

.eventsBlock_eventsList_3SGId3W:hover::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.1);
}

.eventsBlock_sessionDetails_QUw3Tm_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 10px;
  color: #888;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}


