.timelinePointer_wrapper_2OHqJiT {
  position: relative
  /* margin-left: 25px; */
}

.timelinePointer_wrapper_2OHqJiT:hover .timelinePointer_pin_3pkeI4L {
  border: solid thin rgba(0,0,0,0.2);
}

.timelinePointer_wrapper_2OHqJiT  .timelinePointer_icon_3JQ7C2x {
  position: absolute;
  top: 3px;
}

.timelinePointer_wrapper_2OHqJiT .timelinePointer_pin_3pkeI4L {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #EEEEEE;
  position: absolute;
  -webkit-box-shadow: 0 1px 0 0 rgba(0,0,0, 0.1);
          box-shadow: 0 1px 0 0 rgba(0,0,0, 0.1);
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: -5px;
  border: solid thin transparent;
  z-index: 0;
}