.userEvent_userEvent_GEzPM_u {
  border-radius: 3px;
  background-color: rgba(0, 118, 255, 0.05);
  font-family: 'Menlo', 'monaco', 'consolas', monospace;
  padding: 8px 10px;
  margin: 3px 0
}

.userEvent_userEvent_GEzPM_u.userEvent_modalTrigger_F9rApFS {
  cursor: pointer;
}

.userEvent_infoWrapper_20OtqZJ {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.userEvent_title_3W1RR-K {
  display: block;
  color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 13px;
  font-weight: 500;
  margin-right: 10px;
}

.userEvent_message_2VNhliU {
  /* padding-left: 26px; */
  /* padding-top: 10px; */
  font-size: 13px;
  overflow-x: auto
}

.userEvent_message_2VNhliU::-webkit-scrollbar {
  height: 1px;
}