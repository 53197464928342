@-webkit-keyframes sessionItem_fade_1A3s83T {
  0%   { opacity: 1
  }

  50% { opacity: 0
  }

  100%   { opacity: 1
  }
}

@keyframes sessionItem_fade_1A3s83T {
  0%   { opacity: 1
  }

  50% { opacity: 0
  }

  100%   { opacity: 1
  }
}

.sessionItem_sessionItem_1a0toO6 {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s
}

.sessionItem_sessionItem_1a0toO6:hover {
  background-color: #F6F7FF;
  border: solid thin #D0D4F2;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.sessionItem_sessionItem_1a0toO6 {
  border-radius: 3px;
  padding: 10px 10px;
  padding-right: 15px;
  margin-bottom: 15px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: solid thin #EEEEEE
}

.sessionItem_sessionItem_1a0toO6 .sessionItem_favorite_Sw0y0Id {
  opacity: 0
}

.sessionItem_sessionItem_1a0toO6 .sessionItem_favorite_Sw0y0Id[data-favourite=true] {
  opacity: 1;
}

.sessionItem_sessionItem_1a0toO6:hover .sessionItem_playLink_3KWWOLv {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  opacity: 1;
}

.sessionItem_sessionItem_1a0toO6:hover .sessionItem_favorite_Sw0y0Id {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  opacity: 1;
}

.sessionItem_sessionItem_1a0toO6 .sessionItem_iconStack_RiSQ91Z {
  min-width: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.sessionItem_sessionItem_1a0toO6 .sessionItem_iconStack_RiSQ91Z .sessionItem_icons_3XAf3Vq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  margin-bottom: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sessionItem_sessionItem_1a0toO6 .sessionItem_left_1wZxiHP > div[data-collapsed=true] {
  height: 0;
  overflow: hidden;
}

.sessionItem_sessionItem_1a0toO6 .sessionItem_left_1wZxiHP > div {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
  color: #888;
  font-weight: 300;
}

.sessionItem_sessionItem_1a0toO6 .sessionItem_left_1wZxiHP .sessionItem_fid_3YdLrKU span {
  margin-bottom: 2px;
}

.sessionItem_sessionItem_1a0toO6 .sessionItem_left_1wZxiHP .sessionItem_fid_3YdLrKU .sessionItem_fidLabel_QV_jAyS {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sessionItem_sessionItem_1a0toO6 .sessionItem_left_1wZxiHP .sessionItem_iconDetails_1T2kYW6 {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sessionItem_sessionItem_1a0toO6 .sessionItem_count_T_swUx6 {
  font-size: 18px;
  font-weight: 400;
}

.sessionItem_playLink_3KWWOLv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer
}

.sessionItem_playLink_3KWWOLv[data-viewed=true] {
  opacity: 1;
}

.sessionItem_playLink_3KWWOLv > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sessionItem_liveTag_sza68ZF {
  height: 26px;
  width: 56px;
  border-radius: 3px;
  background-color: #42AE5E;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px
}

.sessionItem_liveTag_sza68ZF svg {
  -webkit-animation: sessionItem_fade_1A3s83T 1s infinite;
          animation: sessionItem_fade_1A3s83T 1s infinite;
}