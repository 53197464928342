.automateButton_automateBtnWrapper_101eqRT {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.automateButton_automateButton_10U4frK {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: 300;
  cursor: pointer
}

.automateButton_automateButton_10U4frK:hover {
  color: white;
}

.automateButton_automateButton_10U4frK a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 30px;
  height: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: white;
  line-height: 15px;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  background-color: #394EFF;
  width: 100%;
}

.automateButton_automateButton_10U4frK a:hover {
  background-color: #2331A8;
}

.automateButton_automateButton_10U4frK a .automateButton_subText__OanQgG {
  font-size: 10px;
  font-weight: 300;
  color: rgba(255,255,255,.5);
}

