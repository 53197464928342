.installDocs_snippetWrapper_I4c0jdE {
  position: relative
}

.installDocs_snippetWrapper_I4c0jdE .installDocs_codeCopy_L3i2TjB {
  position: absolute;
  right: 0px;
  top: -3px;
  z-index: 99;
  padding: 5px 10px;
  color: #394EFF;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.installDocs_snippetWrapper_I4c0jdE .installDocs_codeCopy_L3i2TjB:hover {
  background-color: #ddd;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.installDocs_snippetWrapper_I4c0jdE .installDocs_snippet_31NCOLz {
  overflow: hidden;
  line-height: 20px;
  border-radius: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}