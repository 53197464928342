.Toastify__close-button {
  text-indent: -1000em;
  content: '';
  width: 15px;
  height: 15px;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-x' viewBox='0 0 16 16' fill='gray-medium'%3E %3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E %3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center center !important;
}

.Toastify__toast {
  background-color: white !important;
  color: #888;
  border-left: solid 5px transparent
}

.Toastify__toast.Toastify__toast--default {
  border-color: #394EFF;
}

.Toastify__toast.Toastify__toast--info {
  border-color: #FFFBE5;
}

.Toastify__toast.Toastify__toast--success {
  border-color: #394EFF;
}

.Toastify__toast.Toastify__toast--warning {
  background: #E28940;
}

.Toastify__toast.Toastify__toast--error {
  background: #cc0000;
  border-color: #cc0000;
}