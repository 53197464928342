.issueListItem_title_1sr4vJJ {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.issueListItem_wrapper_CrK-j_c {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  padding: 8px 14px;
  /* margin: 0 -14px; */
  height: 70px;
  background-color: white;
  border-bottom: solid thin #ddd
}

.issueListItem_wrapper_CrK-j_c:hover {
  background-color: #F6F7FF;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}