.itemMenu_wrapper_2JS_GQj {
  position: relative;
  display: inline-block;
}

.itemMenu_menuBtn_3Ya4V-4::before {
  content: '';
  width: 25px;
  height: 25px;
  display: inline-block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-three-dots-vertical' viewBox='0 0 16 16' fill='%23333'%3E %3Cpath d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  /* vertical-align: text-bottom; */
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin: 5px;
}

.itemMenu_menuBtn_3Ya4V-4 {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid transparent;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin: 0 auto;
  cursor: pointer;
}

.itemMenu_menuBtn_3Ya4V-4:hover {
  border-color: #D0D4F2;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background-color: #fff;
}

.itemMenu_menu_3DIKuzU[data-displayed=false] {
  display: none;
}

.itemMenu_menu_3DIKuzU {
  z-index: 10;
  position: absolute;
  right: 31px;
  top: 18px;
  width: 150px;
  background-color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0px 1px 3px 0 #ddd;
          box-shadow: 0px 1px 3px 0 #ddd;
}

.itemMenu_menu_3DIKuzU .itemMenu_menuItem_1bQqH8e {
  cursor: pointer;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #ddd;
  color: #666;
}

.itemMenu_menu_3DIKuzU .itemMenu_menuItem_1bQqH8e .itemMenu_iconWrapper_rl2Gz8U {
  width: 13px;
  height: 13px;
  margin-right: 8px;
}

.itemMenu_menu_3DIKuzU .itemMenu_menuItem_1bQqH8e:hover {
  background-color: #f6f6f6;
}

.itemMenu_menu_3DIKuzU .itemMenu_menuItem_1bQqH8e:last-child {
  border: none;
}

.itemMenu_menu_3DIKuzU .itemMenu_menuItem_1bQqH8e .itemMenu_edit_3GTcJij {
  width: 15px;
  height: 15px;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-pencil' viewBox='0 0 16 16' fill='%23888'%3E %3Cpath d='M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-right: 10px;
}

.itemMenu_menu_3DIKuzU .itemMenu_menuItem_1bQqH8e .itemMenu_copy_jAJwsBv {
  width: 15px;
  height: 15px;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='%23888'%3E%3Cpath d='M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM352 32.491a15.88 15.88 0 0 1 7.431 4.195l51.882 51.883A15.885 15.885 0 0 1 415.508 96H352V32.491zM288 464c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h80v240c0 26.51 21.49 48 48 48h112v48zm128-96c0 8.822-7.178 16-16 16H176c-8.822 0-16-7.178-16-16V48c0-8.822 7.178-16 16-16h144v72c0 13.2 10.8 24 24 24h72v240z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-right: 10px;
}

.itemMenu_menu_3DIKuzU .itemMenu_menuItem_1bQqH8e .itemMenu_remove_2Pet0TV {
  width: 15px;
  height: 15px;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-trash' viewBox='0 0 16 16' fill='%23888'%3E %3Cpath d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z'/%3E %3Cpath fill-rule='evenodd' d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-right: 10px;
}

.itemMenu_menu_3DIKuzU .itemMenu_menuItem_1bQqH8e .itemMenu_enabled_3osumfT {
  width: 15px;
  height: 15px;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' fill='%23888'%3E%3Cpath d='M569.354 231.631C512.969 135.948 407.808 72 288 72 168.14 72 63.004 135.994 6.646 231.63a47.999 47.999 0 0 0 0 48.739C63.032 376.053 168.192 440 288 440c119.86 0 224.996-63.994 281.354-159.631a48.002 48.002 0 0 0 0-48.738zM416 228c0 68.483-57.308 124-128 124s-128-55.517-128-124 57.308-124 128-124 128 55.517 128 124zm125.784 36.123C489.837 352.277 393.865 408 288 408c-106.291 0-202.061-56.105-253.784-143.876a16.006 16.006 0 0 1 0-16.247c29.072-49.333 73.341-90.435 127.66-115.887C140.845 158.191 128 191.568 128 228c0 85.818 71.221 156 160 156 88.77 0 160-70.178 160-156 0-36.411-12.833-69.794-33.875-96.01 53.76 25.189 98.274 66.021 127.66 115.887a16.006 16.006 0 0 1-.001 16.246zM224 224c0-10.897 2.727-21.156 7.53-30.137v.02c0 14.554 11.799 26.353 26.353 26.353 14.554 0 26.353-11.799 26.353-26.353s-11.799-26.353-26.353-26.353h-.02c8.981-4.803 19.24-7.53 30.137-7.53 35.346 0 64 28.654 64 64s-28.654 64-64 64-64-28.654-64-64z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-right: 10px;
}

.itemMenu_menu_3DIKuzU .itemMenu_menuItem_1bQqH8e .itemMenu_disabled_1xC_Rpp {
  width: 15px;
  height: 15px;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' fill='%23888'%3E%3Cpath d='M321.496 406.121l21.718 29.272A331.764 331.764 0 0 1 288 440C168.14 440 63.003 376.006 6.646 280.369a47.999 47.999 0 0 1 0-48.739c25.834-43.84 61.913-81.01 104.943-108.427l19.125 25.777c-39.83 24.942-73.004 59.027-96.499 98.896a16.008 16.008 0 0 0 0 16.246C86.163 352.277 182.135 408 288 408c11.298 0 22.476-.64 33.496-1.879zM141.972 164.155C133.037 183.57 128 205.19 128 228c0 85.822 71.23 156 160 156 5.566 0 11.063-.277 16.479-.815l-23.266-31.359C213.676 348.408 160 294.277 160 228a120.48 120.48 0 0 1 4.664-33.26l-22.692-30.585zM569.354 280.37c-33.709 57.202-84.861 103.039-146.143 130.673l56.931 76.732c4 5.391 2.872 13.004-2.519 17.004l-6.507 4.828c-5.391 4-13.004 2.872-17.003-2.519L95.859 24.225c-4-5.391-2.872-13.004 2.519-17.004l6.507-4.828c5.391-4 13.004-2.872 17.003 2.519l62.189 83.82C216.741 77.883 251.696 72 288 72c119.86 0 224.996 63.994 281.354 159.63a48.005 48.005 0 0 1 0 48.74zM416 228c0-68.483-57.308-124-128-124-28.059 0-54.002 8.754-75.095 23.588l34.709 46.782c20.339-16.584 48.244-18.755 70.523-6.84h-.02c-14.554 0-26.353 11.799-26.353 26.353s11.799 26.353 26.353 26.353c14.554 0 26.353-11.799 26.353-26.353v-.02c15.223 28.465 6.889 64.554-20.679 83.18l38.514 51.911C394.803 306.465 416 269.638 416 228zm125.785 19.877c-29.072-49.333-73.341-90.435-127.66-115.887 55.405 69.029 41.701 170.413-32.734 222.688l22.238 29.973c57.564-24.305 106.246-66.38 138.155-120.527a16.008 16.008 0 0 0 .001-16.247z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-right: 10px;
}   