.sortDropdown_dropdown_2Hh_zWI {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 4px 6px;
  border-radius: 3px;
  color: #333;
  font-weight: 500
}

.sortDropdown_dropdown_2Hh_zWI:hover {
  background-color: #ddd;
}

.sortDropdown_dropdownTrigger_3EP6fiP {
  padding: 4px 8px;
  border-radius: 3px
}

.sortDropdown_dropdownTrigger_3EP6fiP:hover {
  background-color: #ddd;
}

.sortDropdown_dropdownIcon_3obbiB7 {
  margin-top: 2px;
  margin-left: 3px;
}