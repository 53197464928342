.PlayIconLayer_iconWrapper_1fr9biR {
  background-color: rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: all .2s;
  transition: all .2s; /* Animation */
}

.PlayIconLayer_zoomIcon_rL8RbLO {
  opacity: 1;
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
  -webkit-transition: all .8s;
  transition: all .8s;
}