.filterModal_modal_CKdnaaM {
  position: absolute;
  left: 0;
  background-color: white;
  width: -webkit-fill-available;
  min-width: 705px;
  max-width: calc(100vw - 500px);
  border-radius: 3px;
  border: solid thin #ddd;
  -webkit-box-shadow: 0 2px 10px 0 #ddd;
          box-shadow: 0 2px 10px 0 #ddd;
  z-index: 99;
  padding: 20px;
}

.filterModal_hint_2i2t5zW {
  color: #ddd;
  font-size: 12px;
  padding-bottom: 5px;
}

h5.filterModal_title_2lt0sUe {
  margin: 10px 0 3px;
}

.filterModal_filterListDynamic_3Xtu8dU {
  max-height: 350px;
  overflow-y: auto
}

.filterModal_filterListDynamic_3Xtu8dU::-webkit-scrollbar {
  width: 2px;
}

.filterModal_filterListDynamic_3Xtu8dU::-webkit-scrollbar-thumb {
  background: transparent;
}

.filterModal_filterListDynamic_3Xtu8dU::-webkit-scrollbar-track {
  background: transparent;
}

.filterModal_filterListDynamic_3Xtu8dU:hover::-webkit-scrollbar-track {
  background: #f3f3f3;
}

.filterModal_filterListDynamic_3Xtu8dU:hover::-webkit-scrollbar-thumb {
  background: #888;
}

.filterModal_filterListDynamic_3Xtu8dU .filterModal_header_2MWRduI {
  margin-bottom: 10px;
  font-size: 13px;
  color: #596764;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-align: left;
}

.filterModal_filterListDynamic_3Xtu8dU .filterModal_list_3AV7i2N {
  margin-left: -8px;
}

.filterModal_filterListStatic_3QeEoBa {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 33rem;
  min-height: 20px;
  color: #888
}

.filterModal_filterListStatic_3QeEoBa .filterModal_header_2MWRduI {
  margin-bottom: 10px;
  font-size: 13px;
  color: #596764;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-align: left;
}

.filterModal_filterListStatic_3QeEoBa .filterModal_list_3AV7i2N {
  margin-left: -8px;
}

.filterModal_filterListStatic_3QeEoBa .filterModal_filterGroup_1dsFuXp {
  width: 205px;
}

.filterModal_disabled_2nOidmp {
  opacity: 0.5;
  pointer-events: none;
}