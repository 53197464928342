.Network_popupNameTrigger_3SNDqKG {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Network_popupNameContent_hN-VbcO {
  max-width: 600px;
  overflow-wrap: break-word;
}
