@-webkit-keyframes Controls_fade_2BKlPkE {
  0%   { opacity: 1
  }

  50% { opacity: 0
  }

  100%   { opacity: 1
  }
}

@keyframes Controls_fade_2BKlPkE {
  0%   { opacity: 1
  }

  50% { opacity: 0
  }

  100%   { opacity: 1
  }
}

.Controls_controls_3T_SdS- {
  border-top: solid thin #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Controls_buttons_17SA1ah {
  margin-top: 7px;
  padding: 0 30px;
}

.Controls_speedButton_AqvqWKF {
  font-size: 14px;
  padding: 0 10px;
  height: 30px;
  border-radius: 3px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.Controls_skipIntervalButton_2HwI70w {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 12px;
  padding: 0 10px;
  height: 30px;
  border-radius: 3px;
}

.Controls_divider_1DYIcaH {
  height: 30px;
  width: 1px;
  margin: 0 5px;
  background-color: #EEEEEE;
}
