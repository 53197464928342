.console_message_6mevdAE {
  overflow-x: auto;
  margin-left: 10px;
  font-size: 13px;
  overflow-x: auto
}

.console_message_6mevdAE::-webkit-scrollbar {
  height: 2px;
}

.console_line_2TTssts {
  font-family: 'Menlo', 'monaco', 'consolas', monospace;
  padding: 7px 0 7px 15px;
  /* margin-top: -1px; ??? */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  border-bottom: solid thin #EEEEEE;
}

.console_activeRow_3UIqT9Z {
  background-color: #394EFF !important;
  color: white !important;
}

.console_icon_1xNY59C {
  padding-top: 4px;
}