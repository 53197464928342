@-webkit-keyframes LiveTag_fade_3W0RnhF {
  0%   { opacity: 1
  }

  50% { opacity: 0
  }

  100%   { opacity: 1
  }
}

@keyframes LiveTag_fade_3W0RnhF {
  0%   { opacity: 1
  }

  50% { opacity: 0
  }

  100%   { opacity: 1
  }
}

.LiveTag_liveTag_1tZ2nzp {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 26px;
  width: 56px;
  border-radius: 3px;
  background-color: #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #666;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  margin-right: 10px
}

.LiveTag_liveTag_1tZ2nzp svg {
  fill: #666;
}

.LiveTag_liveTag_1tZ2nzp[data-is-live=true] {
  background-color: #42AE5E;
  color: white
}

.LiveTag_liveTag_1tZ2nzp[data-is-live=true] svg {
  fill: white;
  -webkit-animation: LiveTag_fade_3W0RnhF 1s infinite;
          animation: LiveTag_fade_3W0RnhF 1s infinite;
}