.profileSettings_left_1ZMmkT5 {
  padding: 40px;
  width: 320px
}

.profileSettings_left_1ZMmkT5 .profileSettings_info_2qoNix0 {
  color: #888;
  font-weight: 300;
}

.profileSettings_form_2su6L8G {
  margin-top: 30px;
  width: 350px
}

.profileSettings_form_2su6L8G .profileSettings_formGroup_2DbcCL3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 25px
}

.profileSettings_form_2su6L8G .profileSettings_formGroup_2DbcCL3 label {
  font-weight: 500 !important;
  margin-bottom: 3px;
}

.profileSettings_form_2su6L8G .profileSettings_formGroup_2DbcCL3 input {
  background-color: white;
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 4px
}

.profileSettings_form_2su6L8G .profileSettings_formGroup_2DbcCL3 input:-moz-read-only {
  background-color: #f6f6f6;
}

.profileSettings_form_2su6L8G .profileSettings_formGroup_2DbcCL3 input:read-only {
  background-color: #f6f6f6;
}

.profileSettings_passwordPolicy_2Hs-KoV {
  color: #888;
  padding: 5px 0 10px;
  font-size: 13px;
}
