.iconButton_button_2mKvJpt {
  padding: 0px 0.75rem;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  height: 36px;
  font-size: 14px;
  border: none
}

.iconButton_button_2mKvJpt:disabled,
  .iconButton_button_2mKvJpt[disabled] {
  opacity: 0.5 !important;
}

.iconButton_button_2mKvJpt.iconButton_marginRight_2pt_IiK {
  margin-right: 10px;
}

.iconButton_button_2mKvJpt.iconButton_buttonSmall_VNNC2au {
  height: 23px;
  font-size: 12px;
  padding: 0px 10px;
}

.iconButton_button_2mKvJpt.iconButton_circle_2FHgIvs {
  border-radius: 50%;
  width: 36px;
  padding: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.iconButton_button_2mKvJpt.iconButton_shadow_21M5jjx {
  -webkit-box-shadow: 0 1px 5px 0 #ddd;
          box-shadow: 0 1px 5px 0 #ddd;
}

.iconButton_button_2mKvJpt.iconButton_small_3SI4YWj {
  padding: 0px 12px;
  height: 28px;
  width: 28px;
  font-size: 12px
}

.iconButton_button_2mKvJpt.iconButton_small_3SI4YWj .iconButton_label_23stwFS {
  margin-left: 4px;
  line-height: 2px;
}

.iconButton_button_2mKvJpt.iconButton_hasLabel_1qnjpzo {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.iconButton_button_2mKvJpt.iconButton_compact_F4_fBdV {
  padding: 0 8px;
}

.iconButton_button_2mKvJpt.iconButton_tiny_2s7pRfa {
  padding: 0 2px;
  height: 20px;
  font-size: 10px;
}

.iconButton_button_2mKvJpt.iconButton_primary_3ITzUi0 {
  background-color: #394EFF;
  -webkit-box-shadow: 0 0 0 1px rgba(62, 170, 175, .8) inset !important;
          box-shadow: 0 0 0 1px rgba(62, 170, 175, .8) inset !important
}

.iconButton_button_2mKvJpt.iconButton_primary_3ITzUi0 .iconButton_icon_2I9aNCe {
  fill: white;
}

.iconButton_button_2mKvJpt.iconButton_primary_3ITzUi0:hover {
  background-color: #2331A8;
}

.iconButton_button_2mKvJpt.iconButton_outline_2h9hZ2N {
  -webkit-box-shadow: 0 0 0 1px #394EFF inset !important;
          box-shadow: 0 0 0 1px #394EFF inset !important
}

.iconButton_button_2mKvJpt.iconButton_outline_2h9hZ2N .iconButton_label_23stwFS {
  color: #394EFF;
}

.iconButton_button_2mKvJpt.iconButton_outline_2h9hZ2N:hover {
  background-color: #F6F7FF !important;
}

.iconButton_button_2mKvJpt.iconButton_plain_aRRiH2u {
  background-color: transparent
}

.iconButton_button_2mKvJpt.iconButton_plain_aRRiH2u:hover {
  background-color: #F6F7FF;
}

.iconButton_button_2mKvJpt .iconButton_label_23stwFS {
  white-space: nowrap;
  color: #666;
}

.iconButton_button_2mKvJpt:hover {
  background-color: #F6F7FF;
}

.iconButton_button_2mKvJpt {
  &roundedOutline {
    border-radius: 15px;
    -webkit-box-shadow: 0 0 0 1px rgba(62,170,175,1) inset !important;
            box-shadow: 0 0 0 1px rgba(62,170,175,1) inset !important;
    color: #394EFF;
  }
}

.iconButton_primaryText_1bz9VmO .iconButton_label_23stwFS {
  color: #394EFF !important;
}