.circleNumber_number_2hEK9Bv {
  width: 24px;
  height: 24px;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  margin-right: 10px;
}