.contentRender_codeMirror_360VX_S > div {
  border: 1px solid #eee;
  height: auto;
}

.contentRender_para_5lsKteX {
  padding: 3px 0;
}

.contentRender_mention_3-1e_Ma {
  font-weight: 500;
  margin-right: 5px;
}

.contentRender_quote_2pvhF5G {
  padding: 5px;
  padding-left: 10px;
  border-left: solid 2px #ddd;
  color: #666;
  margin: 5px 0;
  margin-left: 10px;
}

.contentRender_code_zngWm_q {
  background-color: lightgray;
  padding: 2px 5px;
}

.contentRender_rule_309Wphk {
  margin: 6px 0;
}