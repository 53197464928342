.blockedIps_wrapper_3Hx13fJ {
  width: 300px;
  padding: 20px
}

.blockedIps_wrapper_3Hx13fJ label {
  color: #888;
  font-size: 12px;
}

.blockedIps_inputWrapper_E986NE1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.blockedIps_list_3Mhviog {
  margin-top: 20px;
  display: none  /*  TODO enable this once the API is Ready */
}

.blockedIps_list_3Mhviog .blockedIps_item_2mOYMiT {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: solid thin #ddd;
  padding: 8px 5px
}

.blockedIps_list_3Mhviog .blockedIps_item_2mOYMiT:hover {
  background-color: #F6F7FF
}

.blockedIps_list_3Mhviog .blockedIps_item_2mOYMiT:hover .blockedIps_actions_3Kr6Pba {
  opacity: 1;
}

.blockedIps_list_3Mhviog .blockedIps_item_2mOYMiT .blockedIps_actions_3Kr6Pba {
  opacity: 0;
}