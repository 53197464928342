.Marker_marker_Sna5twN {
  position: absolute;
  z-index: 100;
  border: 2px dotted transparent;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.Marker_marker_Sna5twN:hover .Marker_index_2b7vMQT {
  opacity: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.Marker_marker_Sna5twN .Marker_index_2b7vMQT {
  opacity: 0.3;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #3EAAAF;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border: solid thin white;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
  color: white;
}

.Marker_marker_Sna5twN .Marker_tooltip_3bKqKF0 {
  padding: 10px;
  border-radius: 3px;
  background-color: #3EAAAF;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  /* position: absolute; */
  /* bottom: 100%; */
  /* left: 0; */
  /* margin-bottom: 20px; */
  color: white
}

.Marker_marker_Sna5twN .Marker_tooltip_3bKqKF0::before {
  top: 100%;
  left: 40%;
  border-color: #3EAAAF transparent transparent transparent;
  content: "";
  display: block;
  border-style: solid;
  border-width: 10px 10px 10px 10px;
  position: absolute;
}

.Marker_active_2MuU52Y {
  border: 2px dotted #3EAAAF
}

.Marker_active_2MuU52Y .Marker_index_2b7vMQT {
  opacity: 1
}