.sessionBar_wrapper_fbORI_7 {
  color: #888
}

.sessionBar_wrapper_fbORI_7:hover {
  background-color: #F6F7FF !important;
  color: #888 !important;
}

.sessionBar_wrapper_fbORI_7 > div {
  background-color: transparent !important;
}