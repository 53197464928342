.sortDropdown_dropdown_17M3OO0 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 4px 6px;
  border-radius: 3px;
  color: #333;
  font-weight: 500
}

.sortDropdown_dropdown_17M3OO0:hover {
  background-color: #ddd;
}

.sortDropdown_dropdownTrigger_12nDtGv {
  padding: 4px 8px;
  border-radius: 3px
}

.sortDropdown_dropdownTrigger_12nDtGv:hover {
  background-color: #ddd;
}

.sortDropdown_dropdownIcon_1po7lww {
  margin-top: 2px;
  margin-left: 3px;
}