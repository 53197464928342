.infoLine_info_1ZKrQeU {
  padding-left: 10px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.infoLine_info_1ZKrQeU >.infoLine_infoPoint_KyD82hk {
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.infoLine_info_1ZKrQeU >.infoLine_infoPoint_KyD82hk:not(:last-child):after {
  content: '';
  margin: 0 10px;
  height: 30px;
  border-right: 1px solid #EEEEEE;
}

.infoLine_info_1ZKrQeU >.infoLine_infoPoint_KyD82hk .infoLine_label_2ivh9q4 {
  font-weight: 500;
  margin-right: 3px;
}

.infoLine_dot_3c1vvFZ {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
}

