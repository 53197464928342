.network_location_32fN-VC {
  min-height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 12px
}

.network_location_32fN-VC > div:last-child {
  font-weight: 300;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 0;
}

.network_popupNameTrigger_3am46xg {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 80%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.network_popupNameContent_2G2kdpM {
  max-width: 600px;
  overflow-wrap: break-word;
}
