.dateRangeDropdown_button_1WIi7Tp {
  padding: 0 8px;
  border-radius: 3px;
  color: #394EFF;
  cursor: pointer;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important
}

.dateRangeDropdown_button_1WIi7Tp span {
  white-space: nowrap;
  margin-right: 5px;
}

.dateRangeDropdown_dropdownTrigger_3bWumOW {
  padding: 4px 6px
}

.dateRangeDropdown_dropdownTrigger_3bWumOW:hover {
  background-color: #ddd;
}

.dateRangeDropdown_dateRangeOptions_3JrfZ6N {
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  border-radius: 3px;
  color: #333;
  font-weight: 500
}

.dateRangeDropdown_dateRangeOptions_3JrfZ6N .dateRangeDropdown_dateRangePopup_1K1b5ae {
  top: 38px;
  bottom: 0;
  z-index: 999;
  position: absolute;
  background-color: white;
  border: solid thin #ddd;
  border-radius: 3px;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  min-width: 773px;
  -webkit-box-shadow: 0px 2px 10px 0 #ddd;
          box-shadow: 0px 2px 10px 0 #ddd;
}

.dateRangeDropdown_dropdown_ktQKVjK {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 4px 6px;
  border-radius: 3px;
  color: #333;
  font-weight: 500
}

.dateRangeDropdown_dropdown_ktQKVjK:hover {
  background-color: #ddd;
}

.dateRangeDropdown_dropdownTrigger_3bWumOW {
  padding: 4px 8px;
  border-radius: 3px
}

.dateRangeDropdown_dropdownTrigger_3bWumOW:hover {
  background-color: #ddd;
}

.dateRangeDropdown_dropdownIcon_2WeGKt1 {
  margin-top: 1px;
  margin-left: 3px;
}

.dateRangeDropdown_customRangeRight_1zTyg8l {
  right: 0 !important;
}