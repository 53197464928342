.session_session_3qzImr3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: calc(100vh - 50px)
  /* background-color: red; */
}

.session_session_3qzImr3[data-fullscreen=true] {
  height: calc(100vh);
}