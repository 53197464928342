.projectCodeSnippet_modalHeader_2uj6l3y {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.projectCodeSnippet_content_1fSEUBG {
  background-color: white !important;
}

.projectCodeSnippet_highLight_3tH4nLG {
  background-color: rgba(204, 0, 0, 0.05);
  color: #cc0000;
  padding: 2px 5px;
  border-radius: 3px;
}

.projectCodeSnippet_snippetsWrapper_38IHw2L {
  position: relative
}

.projectCodeSnippet_snippetsWrapper_38IHw2L .projectCodeSnippet_codeCopy_11Nuuhr {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 99;
  padding: 5px 10px;
  color: #394EFF;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.projectCodeSnippet_snippetsWrapper_38IHw2L .projectCodeSnippet_codeCopy_11Nuuhr:hover {
  background-color: #ddd;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.projectCodeSnippet_snippetsWrapper_38IHw2L .projectCodeSnippet_snippet_1df3xP6 {
  overflow: hidden;
  line-height: 18px;
  border-radius: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.projectCodeSnippet_snippetsWrapper_38IHw2L .projectCodeSnippet_snippet_1df3xP6 > div {
  background-color: #f6f6f6 !important;
}

.projectCodeSnippet_siteInfo_1ZRqzW9 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px
}

.projectCodeSnippet_siteInfo_1ZRqzW9 span {
  color: #394EFF;
}

.projectCodeSnippet_instructions_2h3Ic7d {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 10px;
}

.projectCodeSnippet_closeButton_9RclSlZ {
  margin-left: auto;
  cursor: pointer;
  padding: 5px;
}

.projectCodeSnippet_siteId_1DEULlR {
  font-weight: 500
}

.projectCodeSnippet_siteId_1DEULlR span {
  background: #f6f6f6;
  border-radius: 3px;
  padding: 2px 7px;
  font-weight: normal;
  margin-left: 4px;
  border: solid thin #eee;
}

.projectCodeSnippet_info_3lXOiSt {
  padding: 5px 10px;
  background-color: #ffedd1;
}

.projectCodeSnippet_number_3ZkNube {
  width: 24px;
  height: 24px;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  margin-right: 10px;
}