.issues_modal_31xvJAz {
  width: 400px;
  z-index: 999;
}

.issues_buttonWrapper_1-sx_tR {
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* &:hover {
    background-color: white;
  } */
}

.issues_button_1-aFczd {
  height: 36px !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important
}

.issues_button_1-aFczd:hover {
  background-color: #F6F7FF !important;
}