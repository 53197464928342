.integrationItem_wrapper_2wf5i9- {
  border-radius: 3px;
  /* border: solid thin $gray-light-shade; */
  margin-right: 10px;
  padding: 20px;
  cursor: pointer;
  width: 130px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center
  /* min-height: 250px; */
  /* min-width: 260px; */
  /* max-width: 300px; */
}

.integrationItem_wrapper_2wf5i9-:hover {
  background-color: #F6F7FF;
}

.integrationItem_wrapper_2wf5i9- .integrationItem_description_TF-NLL3 {
  font-weight: 300;
  font-size: 12px;
}

.integrationItem_externalIcon_3WqLxrI {
  position: absolute;
  right: 20px;
  top: 20px;
}

.integrationItem_footer_IfmepA- {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  bottom: 14px;
  left: 20px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  right: 20px;
}

.integrationItem_docsLink_2PUijWn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #888
}

.integrationItem_docsLink_2PUijWn:hover {
  text-decoration: underline;
}

.integrationItem_docsLink_2PUijWn.integrationItem_hidden_2m4DcEZ {
  opacity: 0;
  pointer-events: none;
}

.integrationItem_integratedCheck_1Te9cxN {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #394EFF;
}

.integrationItem_integrated_1PuN9h- {
  background-color: #F6F7FF;
}