.cursor_cursor_2xogD6D {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  /*border-radius: 20px;*/
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M302.189 329.126H196.105l55.831 135.993c3.889 9.428-.555 19.999-9.444 23.999l-49.165 21.427c-9.165 4-19.443-.571-23.332-9.714l-53.053-129.136-86.664 89.138C18.729 472.71 0 463.554 0 447.977V18.299C0 1.899 19.921-6.096 30.277 5.443l284.412 292.542c11.472 11.179 3.007 31.141-12.5 31.141z"/></svg>');
  background-repeat: no-repeat;
  /*border: 4px solid rgba(255, 255, 255, .8);
  margin-left: -10px;
  margin-top: -10px;*/
}

/*@keyframes click {

}*/

/*.click {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background: yellow;
  border-radius: 20px;
  border: 4px solid rgba(255, 255, 255, .8);
  margin-left: -10px;
  margin-top: -10px;
}*/