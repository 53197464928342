.filterItem_filterItem_198AqGF {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 3px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  margin-bottom: 5px;
  max-width: 100%
}

.filterItem_filterItem_198AqGF .filterItem_label_26N8IUB {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filterItem_filterItem_198AqGF:hover {
  background-color: #f6f6f6;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}