.segmentSelection_wrapper_2xPgV3i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  border: solid thin #ddd;
  border-radius: 5px;
  overflow: hidden
}

.segmentSelection_wrapper_2xPgV3i .segmentSelection_item_U8oaNHZ {
  color: #888;
  font-weight: medium;
  padding: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  border-right: solid thin #ddd;
  cursor: pointer;
  background-color: #f6f6f6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center
}

.segmentSelection_wrapper_2xPgV3i .segmentSelection_item_U8oaNHZ span svg {
  fill: #888;
}

.segmentSelection_wrapper_2xPgV3i .segmentSelection_item_U8oaNHZ[data-active=true] {
  background-color: white;
  color: #394EFF
}

.segmentSelection_wrapper_2xPgV3i .segmentSelection_item_U8oaNHZ[data-active=true] span svg {
  fill: #394EFF;
}

.segmentSelection_wrapper_2xPgV3i .segmentSelection_item_U8oaNHZ:last-child {
  border: none;
}

.segmentSelection_wrapper_2xPgV3i .segmentSelection_item_U8oaNHZ:hover {
  background-color: white;
  color: #394EFF
}

.segmentSelection_wrapper_2xPgV3i .segmentSelection_item_U8oaNHZ:hover span svg {
  fill: #394EFF;
}

.segmentSelection_primary_1ECFbFV {
  border: solid thin #394EFF
}

.segmentSelection_primary_1ECFbFV .segmentSelection_item_U8oaNHZ {
  color: #394EFF;
  background-color: white
}

.segmentSelection_primary_1ECFbFV .segmentSelection_item_U8oaNHZ[data-active=true] {
  background-color: #394EFF;
  color: white;
}

.segmentSelection_small__dMI1NG .segmentSelection_item_U8oaNHZ {
  padding: 4px 8px;
}