.SlowestResources_topActions_3LaKhAQ {
  position: absolute;
  top: 0px;
  right: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}