.listItem_name_2i5QUsr {
  min-width: 55%;
}

.listItem_sessions_tyqlmnF {
  width: 6%;
}

.listItem_users_Y6PMg3l {
  width: 5%;
}

.listItem_occurrence_1zZ6mSl {
  width: 15%;
  min-width: 152px;
}
