.preferencesMenu_wrapper_uMOMibj {
  position: fixed;
  top: 81px;
  width: 200px;
}

.preferencesMenu_header_Su-7ZvM {
  margin-bottom: 15px
}

.preferencesMenu_header_Su-7ZvM .preferencesMenu_label_1g5One2 {
  text-transform: uppercase;
  color: gray;
  letter-spacing: 0.2em;
}

.preferencesMenu_header_Su-7ZvM .preferencesMenu_manageButton_1Dxul_R {
  margin-left: 5px;
  font-size: 12px;
  color: #394EFF;
  cursor: pointer;
  padding: 2px 5px;
  border: solid thin transparent;
  border-radius: 3px;
  margin-bottom: -3px;
}

.preferencesMenu_header_Su-7ZvM .preferencesMenu_manageButton_1Dxul_R:hover {
  background-color: #ddd;
  color: #333;
}

.preferencesMenu_divider_1c_SbnF {
  height: 1px;
  width: 100%;
  background-color: #ddd;
}