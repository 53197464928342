.widgetAutoComplete_searchWrapper_s8RHvvr {
  width: 250px;
  padding: 10px 5px;
  height: 30px;
  border-radius: 3px;
  cursor: pointer;
  border: solid thin transparent;
  margin: 0 -5px
}

.widgetAutoComplete_searchWrapper_s8RHvvr:after {
  content: '';
  width: 100%;
  border-bottom: dotted thin #ddd;
  position: absolute;
  right: 5px;
  bottom: 0;
}

.widgetAutoComplete_searchWrapper_s8RHvvr input {
  padding: 0 5px;
}

.widgetAutoComplete_searchWrapper_s8RHvvr:hover {
  border: solid thin #ddd
}

.widgetAutoComplete_searchWrapper_s8RHvvr:hover:after {
  display: none;
}

.widgetAutoComplete_searchWrapper_s8RHvvr.widgetAutoComplete_focused_xKmgoS4 {
  background-color: #ddd
}

.widgetAutoComplete_searchWrapper_s8RHvvr.widgetAutoComplete_focused_xKmgoS4:after {
  display: none;
}

.widgetAutoComplete_selected_1i1NkzJ {
  width: 100%
}

.widgetAutoComplete_selected_1i1NkzJ span {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.widgetAutoComplete_search_3x4aG6J {
  padding: 8px 0;
  border: none
}

.widgetAutoComplete_search_3x4aG6J:focus, .widgetAutoComplete_search_3x4aG6J:active {
  border: none !important;
}

.widgetAutoComplete_menuWrapper_3cDdT8p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column
}

.widgetAutoComplete_menuWrapper_3cDdT8p > div {
  -ms-flex-negative:  0;
      flex-shrink:  0;
}

.widgetAutoComplete_menuWrapper_3cDdT8p {
  border: solid thin #ddd;
  top: 31px;
  z-index: 1;
  margin-left: -5px;
  max-height: 180px;
  overflow-y: auto
}

.widgetAutoComplete_menuWrapper_3cDdT8p::-webkit-scrollbar {
  width: 1px;
}

.widgetAutoComplete_optionItem_3uuIy8S {
  border-bottom: solid thin #ddd;
  padding: 8px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer
}

.widgetAutoComplete_optionItem_3uuIy8S:last-child {
  border-bottom: none;
}

.widgetAutoComplete_optionItem_3uuIy8S:hover {
  background-color: #f6f6f6;
}